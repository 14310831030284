<template>
	<div class="navbar">
		<!-- <Hamburger id="hamburger-container" class="hamburger-container" @toggleClick="hamburgerToggleClick" :is-active="sidebar.opened"/> -->
		
		<div class="right-menu">
			<!-- <template v-if="!isMobile">
				<ScreenFullMode id="screenfullSvg" class="right-menu-item hover-effect"/>
			</template> -->
			<!-- <SwitchLanguage class="right-menu-item hover-effect"/> -->
			<el-dropdown id="user-info" class="avatar-container right-menu-item hover-effect" trigger="click">
				<div class="avatar-wrapper">
					<img ref="userAvatar" :src="userAvatar" @error="userAvatarLoadError()" class="user-avatar"/>
					<span class="nickName">{{nickname}}</span>
					<i class="el-icon-caret-bottom"></i>
				</div>
				<el-dropdown-menu slot="dropdown">
					<router-link to="/home">
						<el-dropdown-item>{{translate('COMMON.HOME')}}</el-dropdown-item>
					</router-link>
					<router-link to="/updatePassword">
						<el-dropdown-item>修改密码</el-dropdown-item>
					</router-link>
					<el-dropdown-item divided>
						<span style="display:block;" @click="logout">{{translate('LOGIN.LOGOUT')}}</span>
					</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
	</div>
</template>

<script>
	import {mapActions,mapState} from 'vuex';
	import Hamburger from '@/components/Hamburger';
	import Breadcrumb from '@/components/Breadcrumb';
	import ScreenFullMode from '@/components/ScreenFullMode';
	import SwitchLanguage from '@/components/SwitchLanguage';
    export default {
        name: "HeaderNavbar",
		components: {
			Hamburger,
			Breadcrumb,
			ScreenFullMode,
			SwitchLanguage
		},
		data() {
        	return{
				defaultUserAvatar: ''
			}
		},
		computed: {
			...mapState('app', ['sidebar','isMobile','loginUser']),
			userAvatar(){
				return this.defaultUserAvatar || this.loginUser.userInfo.avatar;
			},
			nickname(){
				return this.loginUser.userInfo.nickname
			}
		},
		methods: {
			...mapActions('app', ['toggleSideBar', 'logout']),
			hamburgerToggleClick: function () {
				this.toggleSideBar();
			},
			userAvatarLoadError: function () {
				this.defaultUserAvatar = "https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif?imageView2/1/w/80/h/80";
			}
		}
    }
</script>

<style lang="scss" scoped>
	.navbar {
		height: 50px;
		overflow: hidden;
		position: relative;
		top: 0;
		width: 100%;
		background: #fff;
		box-shadow: 0 1px 4px rgba(0,21,41,.08);

		.hamburger-container {
			line-height: 46px;
			height: 100%;
			float: left;
			cursor: pointer;
			transition: background .3s;
			-webkit-tap-highlight-color:transparent;

			&:hover {
				background: rgba(0, 0, 0, .025)
			}
		}

		.breadcrumb-container {
			float: left;
		}

		.errLog-container {
			display: inline-block;
			vertical-align: top;
		}

		.right-menu {
			float: right;
			height: 100%;
			line-height: 50px;

			&:focus {
				outline: none;
			}

			.right-menu-item {
				display: inline-block;
				padding: 0 8px;
				height: 100%;
				font-size: 18px;
				color: #5a5e66;
				vertical-align: text-bottom;

				&.hover-effect {
					cursor: pointer;
					transition: background .3s;

					&:hover {
						background: rgba(0, 0, 0, .025)
					}
				}
			}

			.avatar-container {
				margin-right: 30px;

				.avatar-wrapper {
					margin-top: 5px;
					position: relative;

					.user-avatar {
						cursor: pointer;
						width: 40px;
						height: 40px;
						border-radius: 10px;
					}

					.el-icon-caret-bottom {
						cursor: pointer;
						position: absolute;
						right: -20px;
						top: 16px;
						font-size: 12px;
					}
				}
			}
		}
	}
	.nickName{
		line-height: 40px;
		padding: 0 5px 0 10px;
		vertical-align: top;
	}
</style>
