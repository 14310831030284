
import {routerPath, routerPathA} from '../tool';
import Layout from '@/views/layout/Layout.vue'

export default {
    path: '/merchantManage',
    name: 'merchantManage',
    component: Layout,
    meta: {
        title: '商户管理',
        hasIndex: true,
		tabView: true
    },
    children: [
        routerPathA('merchantManage', 'merchantQuery', '商户查询',false),
        routerPathA('merchantManage', 'merchantQuery', '商户查询',false),
        routerPathA('merchantManage', 'auditMerchantManage', '审核商户管理',false),
        routerPathA('merchantManage', 'rateChangeApply', '费率变更申请')
    ]
};
